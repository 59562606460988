/* VENDOR - Default fall-backs and external files.
========================================================================== */
/* BASE - Base Variable file along with starting point Mixins and Placeholders.
========================================================================== */
/* FRAMEWORK - Structure and layout files. Partials
========================================================================== */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::after,
*::before {
  box-sizing: inherit; }

body {
  background: #fff !important;
  margin: 0;
  font-family: "Rubik", "Helvetica", sans-serif;
  font-size: 16px; }

a {
  text-decoration: none !important;
  color: #1A1A1A !important; }

input:focus {
  outline: none !important; }

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto; }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.none {
  list-style-type: none;
  padding-left: 0; }

.center {
  vertical-align: middle;
  margin: auto; }

.bold {
  font-weight: 700 !important; }

.medium {
  font-weight: 500 !important; }

.regular {
  font-weight: 400; }

.light {
  font-weight: 100; }

.font-serif {
  font-family: "adobe-caslon-pro", serif; }

.hxl {
  font-size: 8.75em;
  color: #0E0E0E;
  font-weight: 400;
  white-space: pre-wrap; }

.h1,
h1 {
  font-size: 3.333333em;
  color: #0E0E0E;
  font-weight: 400;
  white-space: pre-wrap; }

.h2,
h2 {
  font-size: 2.1875em;
  color: #0E0E0E;
  font-weight: 400;
  white-space: pre-wrap; }

.h3,
h3 {
  font-size: 1.825em;
  line-height: 1.3em;
  margin: 0.25em 0 !important;
  color: #0E0E0E;
  font-weight: 400;
  white-space: pre-wrap; }

.h4,
h4 {
  font-size: 1.325em;
  margin: 0.8em 0 !important;
  color: #0E0E0E;
  font-weight: 100;
  white-space: pre-wrap; }

.h5,
h5 {
  font-size: 1.25em;
  margin: 0.5em 0 !important;
  color: #0E0E0E;
  font-weight: 100;
  white-space: pre-wrap; }

.h6,
h6 {
  font-size: 1em;
  margin: 0.5em 0 !important;
  color: #0E0E0E;
  font-weight: 400;
  white-space: pre-wrap; }

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid #1A1A1A !important; }

ul.tc {
  list-style-type: circle !important; }

li {
  display: list-item !important; }

a {
  cursor: url("https://firebasestorage.googleapis.com/v0/b/porta-servicios.appspot.com/o/circle.svg?alt=media&token=63d79fa6-c178-45d8-a404-48fedfc5085a") 16 16, auto; }

.text-nobrand,
text-nobrand:active,
text-nobrand:hover {
  color: #1A1A1A !important; }

.h-back {
  margin-bottom: -70px;
  width: 100%;
  z-index: -1; }

.logo {
  height: auto !important;
  width: 100px !important;
  background-repeat: no-repeat;
  background-position: center;
  white-space: normal;
  position: relative;
  background-size: cover; }

.isotype {
  width: 35px;
  height: 35px;
  padding: 0; }

.isotype-system {
  width: 70px;
  height: 70px;
  padding: 0; }

.macintosh {
  width: 100px;
  height: 100px;
  padding: 0; }

.isotype-face {
  cursor: pointer;
  width: 35px;
  height: 35px;
  padding: 0; }

.isotype-happyface {
  animation: spinner 1s; }

.isotype-nuetralface {
  animation: spinnerBack 1s; }

.isotype-face-loop {
  width: 100px;
  height: 100px;
  padding: 0;
  animation: spinner 2s infinite; }

@keyframes spinner {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  to {
    -moz-transform: rotateY(-360deg);
    -ms-transform: rotateY(-360deg);
    transform: rotateY(-360deg); } }

@keyframes spinnerBack {
  from {
    -moz-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    transform: rotateY(0deg); }
  to {
    -moz-transform: rotateY(360deg);
    -ms-transform: rotateY(360deg);
    transform: rotateY(360deg); } }

.changeColor {
  animation: changeColor 100s infinite; }

@keyframes changeColor {
  0% {
    background-color: #9bd6fb; }
  10% {
    background-color: #fcef9a; }
  25% {
    background-color: #f2a3c7; }
  50% {
    background-color: #76be93; }
  75% {
    background-color: #f2a3c7; }
  90% {
    background-color: #fcef9a; }
  100% {
    background-color: #9bd6fb; } }

.circle-color {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: solid 3px #1A1A1A;
  animation: changeColor 100s infinite; }

.circle-color-loop {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: solid 3px #1A1A1A;
  animation: changeColor 50s infinite; }

.rectangle-grey {
  width: 100%;
  height: 10vh;
  background-color: #C4C4C4; }

.google-map {
  width: 100%; }

.team {
  background-color: #f6f5f5; }

select {
  width: 120px;
  padding: 5px 35px 5px 5px;
  font-size: 16px;
  border: 2px solid #1A1A1A;
  border-radius: 0;
  height: 34px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff; }

.pt-80 {
  padding-top: 80px; }

.pb-80 {
  padding-bottom: 80px; }

.pt-100 {
  padding-top: 100px; }

.pb-100 {
  padding-bottom: 100px; }

.menu {
  position: fixed;
  width: 100%;
  z-index: 1;
  background-color: #fff; }

.menu-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1; }

.menu-link:hover {
  text-decoration: underline; }

a.current {
  text-decoration: underline !important; }

.menu-mobile,
.blurheader {
  background: rgba(255, 255, 255, 0.6);
  z-index: 10000000000; }

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 2em;
  height: 30px;
  right: 1.25em;
  top: 1.25em; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #1A1A1A; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

/* Color/shape of close button cross */
.bm-cross {
  background: #1A1A1A; }

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%; }

/* General sidebar styles */
.bm-menu {
  background: #fff;
  padding: 1.5em 1em 0;
  font-size: 2em;
  color: #fff; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; }

/* Wrapper for item list */
.bm-item-list {
  color: #fff; }

/* Individual item */
.bm-item {
  padding-bottom: 1em;
  display: inline-block;
  outline: 0; }

/* Styling of overlay */
.bm-overlay {
  width: 0 !important;
  background: rgba(0, 0, 0, 0.3); }

.blurheader {
  background: transparent;
  overflow: hidden;
  z-index: 10000;
  background: #fff;
  left: 0; }

.color-brand {
  background-color: #FFE473; }

.fixedbutton {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0; }

.overflow-hidden {
  overflow: hidden !important; }

.overflow-auto {
  overflow: auto !important; }

.vh-100 {
  height: 100vh !important; }

.mh-100 {
  max-height: 100% !important; }

.app_scroll {
  margin: 0;
  overflow: hidden;
  height: 100%; }

.position-absolute {
  position: absolute !important; }

.position-relative {
  position: relative !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.inline-block {
  display: inline-block; }

.menu-center {
  text-align: center; }

footer {
  width: 100%;
  z-index: 1;
  background-color: #1A1A1A;
  color: #fff !important; }

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  color: #fff; }

.h-100 {
  height: 100%; }

.text-right {
  text-align: right; }

.link:hover,
.underline {
  text-decoration: underline; }

.link-underline,
.link-underline:hover {
  text-decoration: underline !important; }

.logo-header-left {
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 1 !important; }

.logo-header-right {
  position: fixed;
  right: 20px;
  top: 20px;
  z-index: 1 !important; }

.underline {
  text-decoration: underline !important; }

.nav-center-y-right {
  padding-top: calc(50vh) !important;
  position: fixed;
  right: 0; }

.nav-center-y-left {
  padding-top: calc(50vh) !important;
  position: fixed;
  left: 0; }

button {
  background-color: white;
  border: none;
  color: #262a36;
  padding: 15px 32px;
  border-radius: 100px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.33em; }

button:hover {
  background-color: #1A1A1A;
  color: white; }

button:active {
  background-color: #262a36;
  color: #e8e8e8; }

btn-link:focus,
btn-primary-sm:focus,
btn-primary:focus,
btn-secundary-sm:focus,
btn-secundary:focus,
button:focus {
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important; }

.btn-primary {
  background-color: #1A1A1A !important;
  color: #fff !important;
  border-radius: 100px !important;
  border: none !important;
  padding: 12px 40px 14px !important;
  font-size: 16px !important;
  font-weight: 400; }

.btn-primary:hover {
  background-color: #fff;
  border-color: none !important;
  opacity: 0.95; }

.btn-filter,
.nav-link {
  background-color: #fff !important;
  border: 1px solid #1A1A1A !important;
  color: #1A1A1A !important;
  border-radius: 100px !important;
  padding: 6px 32px !important;
  font-size: 14px !important;
  font-weight: 500;
  white-space: pre-wrap; }

.box {
  outline: 1px solid #1A1A1A;
  background-color: #fff;
  height: 100%;
  z-index: 10;
  transition: transform 0.2s; }

.box-r {
  border: 1px solid #1A1A1A;
  background-color: #fff;
  border-radius: 6px !important;
  height: 100%;
  z-index: 10;
  transition: transform 0.2s; }

.box-select {
  border: 1px solid #1A1A1A;
  background-color: #fff;
  height: 100%;
  z-index: 10;
  transition: transform 0.2s; }

.box-select:hover {
  background-color: #FFE473; }

.hide {
  display: none; }

li {
  display: inline; }

ul {
  margin: 0;
  padding: 0; }

ul li a {
  color: #1A1A1A;
  text-decoration: none; }

input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  border: 0;
  border-radius: 100px;
  box-sizing: border-box;
  background-size: 25px;
  background-position: 98% center;
  background-repeat: no-repeat; }

.card-wrapper {
  animation: reveal 0.8s ease-in-out forwards; }

.card-category {
  background-color: #fff;
  border: 1px solid #eee;
  box-sizing: border-box;
  padding: 24px;
  min-height: 14rem;
  transition: box-shadow 0.25s ease-in-out; }

.content-box-lg {
  background-size: auto 100% !important;
  height: calc(100vh) !important; }

.text-content {
  padding-left: 15em !important;
  padding-right: 15em !important; }

.img-work {
  width: 100% !important;
  padding-bottom: 100%;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-size: auto 100%; }

.img-work-v {
  width: 100% !important;
  padding-bottom: 141.84397163121%;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-size: auto 100%; }

.img-work-h {
  width: 100% !important;
  padding-bottom: 70.19999999999999%;
  background-position: center;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-size: 100% auto; }

.img-quote {
  width: 100% !important;
  height: 40vh !important;
  object-fit: cover; }

.img-universo {
  width: 100% !important;
  height: auto;
  object-fit: cover; }

.icon {
  display: inline;
  width: 30px !important;
  margin-bottom: 3px;
  opacity: 1;
  vertical-align: middle;
  padding: 0;
  padding-right: 2px; }

.iconStar {
  display: inline;
  width: 20px !important;
  margin-bottom: 3px;
  opacity: 1;
  vertical-align: middle;
  padding: 0;
  padding-right: 2px; }

.avatar {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  padding: 0;
  font-size: 0;
  object-fit: cover; }

.avatar-team {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  padding: 0;
  font-size: 0;
  object-fit: cover; }

.avatar-border {
  border: 1px solid #e4e4e4; }

.avatar-empty {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0; }

.rrss-icon {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0;
  font-size: 0;
  object-fit: cover; }

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px; }

.link-button {
  border: 0 #fff !important;
  background-color: #fff !important;
  font-weight: 400 !important;
  font-size: 14px;
  cursor: pointer !important;
  color: #1A1A1A; }

.img-100 {
  height: 100vh !important;
  width: 100% !important;
  object-fit: cover; }

.img-3x4 {
  width: auto;
  height: auto;
  padding-top: 125%;
  background-size: 100% auto !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center !important;
  white-space: normal !important;
  position: relative !important; }

.img-4x3 {
  width: auto;
  height: auto;
  padding-top: 50%;
  background-size: 100% auto !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center !important;
  white-space: normal !important;
  position: relative !important; }

.shadow {
  border: 0 solid #dedede;
  box-shadow: 0 10px 85px 0 rgba(0, 0, 0, 0.3); }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.text-center {
  text-align: center; }

.center {
  margin: auto;
  width: 50%; }

.fix-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 3em; }

.none {
  text-decoration: none;
  list-style: none;
  display: inline; }

.description-3 {
  height: 50px; }

.inline {
  display: inline; }

.float-right {
  float: right; }

.float-left {
  float: left; }

.text-left {
  left: 0;
  text-align: left; }

.text-right {
  right: 0;
  text-align: right; }

.center-block {
  margin-left: auto;
  margin-right: auto;
  display: block; }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.ml-0 {
  margin-left: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25em !important; }

.pt-1,
.py-1 {
  padding-top: 0.25em !important; }

.pr-1,
.px-1 {
  padding-right: 0.25em !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25em !important; }

.pl-1,
.px-1 {
  padding-left: 0.25em !important; }

.p-2 {
  padding: 0.5em !important; }

.pt-2,
.py-2 {
  padding-top: 0.5em !important; }

.pr-2,
.px-2 {
  padding-right: 0.5em !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5em !important; }

.pl-2,
.px-2 {
  padding-left: 0.5em !important; }

.p-3 {
  padding: 1em !important; }

.pt-3,
.py-3 {
  padding-top: 1em !important; }

.pr-3,
.px-3 {
  padding-right: 1em !important; }

.pb-3,
.py-3 {
  padding-bottom: 1em !important; }

.pl-3,
.px-3 {
  padding-left: 1em !important; }

.p-team {
  padding-left: 15em !important;
  padding-right: 15em !important; }

.p-4 {
  padding: 1.5em !important; }

.pt-4,
.py-4 {
  padding-top: 1.5em !important; }

.pr-4,
.px-4 {
  padding-right: 1.5em !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5em !important; }

.pl-4,
.px-4 {
  padding-left: 1.5em !important; }

.p-5 {
  padding: 3em !important; }

.pt-5,
.py-5 {
  padding-top: 3em !important; }

.pr-5,
.px-5 {
  padding-right: 3em !important; }

.pb-5,
.py-5 {
  padding-bottom: 3em !important; }

.pl-5,
.px-5 {
  padding-left: 3em !important; }

.pt-10,
.py-10 {
  padding-top: 6em !important; }

.pb-10,
.py-10 {
  padding-bottom: 6em !important; }

.pt-15,
.py-15 {
  padding-top: 9em !important; }

.pb-15,
.py-15 {
  padding-bottom: 9em !important; }

.pb-15m {
  padding-bottom: 9em !important; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

.order-0 {
  -ms-flex-order: 0;
  order: 0; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.d-none {
  display: none !important; }

@media (max-width: 576px) {
  .hxl {
    font-size: 2em !important; }
  .h-back {
    margin-bottom: 0px; }
  .hide-sm {
    display: none; }
  .slider {
    height: 80vh; }
  .text-content {
    padding-left: 0em !important;
    padding-right: 0em !important; } }

@media (min-width: 576px) {
  .h-back {
    margin-bottom: 0px; }
  .text-content {
    padding-left: 0em !important;
    padding-right: 0em !important; }
  .hxl {
    font-size: 3em !important; }
  .hide-sm {
    display: none; }
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .d-sm-block {
    display: block !important; }
  .d-sm-none {
    display: none !important; } }

@media (max-width: 768px) {
  .h2,
  h2 {
    font-size: 1.5625em !important; }
  .hide-md {
    display: none !important; }
  .p-5 {
    padding: 5px !important; }
  .px-5 {
    padding: 3em 0 !important; }
  .pb-15m {
    padding-bottom: 3em !important; }
  .text-center-md {
    text-align: center !important;
    padding: 0 !important; }
  .fixedbutton {
    position: relative; }
  footer {
    height: auto; } }

@media (min-width: 768px) {
  .h-back {
    margin-bottom: 0px; }
  .text-content {
    padding-left: 1em !important;
    padding-right: 1em !important; }
  .hxl {
    font-size: 4em !important; }
  #left {
    position: absolute;
    top: 80px;
    bottom: 80px;
    left: 0;
    overflow-y: scroll; }
  #right {
    position: absolute;
    top: 80px;
    bottom: 80px;
    right: 0;
    overflow-y: scroll; }
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .d-md-none {
    display: none !important; }
  .d-md-block {
    display: block !important; } }

@media (max-width: 992px) {
  .hide-lg {
    display: none; } }

@media (min-width: 992px) {
  .h-back {
    margin-bottom: -50px; }
  .text-content {
    padding-left: 7em !important;
    padding-right: 7em !important; }
  .hxl {
    font-size: 5em !important; }
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .d-lg-block {
    display: block !important; }
  .d-lg-none {
    display: none !important; } }

@media (max-width: 1200px) {
  .hide-xl {
    display: none; }
  .p-team {
    padding-left: 0em !important;
    padding-right: 0em !important; } }

@media (min-width: 1200px) {
  .h-back {
    margin-bottom: -70px; }
  .text-content {
    padding-left: 15em !important;
    padding-right: 15em !important; }
  .hxl {
    font-size: 8em !important; }
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .d-xl-none {
    display: none !important; }
  .d-xl-block {
    display: block !important; } }

/* MODULES - Re-usable site elements.
========================================================================== */
